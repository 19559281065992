import { Container } from "react-bootstrap"
import React from "react"
import { Link } from "gatsby"

export const Footer = () => {
  return (
    <Container fluid className="px-0">
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <h6>About</h6>
              <p className="text-justify">
                Secure Ride Automotive{" "}
                <i>WANTS TO REMOVE ALL WORRY FROM DRIVING. </i>We specialize in
                securing and protecting your vehicle from external threats and
                incidents.
              </p>
            </div>

            <div className="col-xs-6 col-md-3">
              <h6>Products</h6>
              <ul className="footer-links">
                <li>
                  <Link to="/autoguard">Autoguard</Link>
                </li>
                <li>
                  <Link to="/protection">Protection</Link>
                </li>
                <li>
                  <Link to="/anti-theft">Anti-Theft</Link>
                </li>
                <li>
                  <Link to="/key-remote">Key & Remote</Link>
                </li>
                <li>
                  <Link to="/tire-rim">Tire & Rim</Link>
                </li>
              </ul>
            </div>

            <div className="col-xs-6 col-md-3">
              <h6>Quick Links</h6>
              <ul className="footer-links">
                <li>
                  <Link to={"/contact"}>Contact Us</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                {/* <li>
                  <a href="/sitemap/sitemap-0.xml">Sitemap</a>
                </li> */}
              </ul>
            </div>
          </div>
          <hr />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-6 col-xs-12">
              <p className="copyright-text">
                Copyright &copy; {new Date().getFullYear()} All Rights Reserved
                by Secure Ride Automotive.
              </p>
            </div>

            {/* <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className="social-icons">
              <li>
                <a className="facebook" href="#">
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a className="twitter" href="#">
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a className="dribbble" href="#">
                  <i className="fa fa-dribbble"></i>
                </a>
              </li>
              <li>
                <a className="linkedin" href="#">
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
            </ul>
          </div> */}
          </div>
        </div>
      </footer>
    </Container>
  )
}
