import React from "react"
import { Link } from "gatsby"
import logo from "../images/membership-logo.png"
import { Navbar, Nav } from "react-bootstrap"
import { MdPhoneAndroid, MdLocalPhone, MdEmail } from "react-icons/md"

const CustomNavbar = ({ pageInfo }) => {
  const phoneNum = process.env.GATSBY_PHONE_NUMBER
  const email = process.env.GATSBY_EMAIL_ADDRESS

  return (
    <>
      <Navbar variant="light" expand="lg" className="site-navbar pl-4 pr-4">
        {/* <Container> */}
        <Navbar.Brand className="col col-lg-1">
          <Link to="/">
            <img
              className="nav-logo"
              // max-width="50%"
              // height="auto"
              src={logo}
              alt="Secure Ride Logo"
              // style={{ margin: "0px", padding: "0px" }}
            />
          </Link>
        </Navbar.Brand>
        <Nav padding-right="10px" className="mr-2 ml-2 d-lg-none">
          <span>
            <a className="text-dark" href={`tel: ${phoneNum}`}>
              Call <MdPhoneAndroid size="35" color="#221e20" />
            </a>
          </span>
        </Nav>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          className="justify-content-center"
          id="basic-navbar-nav"
        >
          <Nav className="lg-col-6" activeKey={pageInfo && pageInfo.pageName}>
            <Link to="/autoguard" className="link-no-style nav-link">
              <Nav.Link
                className="text-dark nav-link"
                as="span"
                eventKey="autoguard"
              >
                Autoguard
              </Nav.Link>
            </Link>
            <Link to="/anti-theft" className="link-no-style nav-link">
              <Nav.Link
                className="text-dark nav-link"
                as="span"
                eventKey="anti-theft"
              >
                Anti-Theft
              </Nav.Link>
            </Link>
            <Link to="/key-remote" className="link-no-style nav-link">
              <Nav.Link
                className="text-dark nav-link"
                as="span"
                eventKey="key-remote"
              >
                Key & Remote
              </Nav.Link>
            </Link>
            <Link to="/tire-rim" className="link-no-style nav-link">
              <Nav.Link
                className="text-dark nav-link"
                as="span"
                eventKey="tire-rim"
              >
                Tire & Rim
              </Nav.Link>
            </Link>
            <Link to="/protection" className="link-no-style nav-link">
              <Nav.Link
                className="text-dark nav-link"
                as="span"
                eventKey="protection"
              >
                Protection
              </Nav.Link>
            </Link>
            <Link to="/dealerships" className="link-no-style nav-link">
              <Nav.Link
                className="text-dark nav-link"
                as="span"
                eventKey="dealerships"
              >
                Locations
              </Nav.Link>
            </Link>
            <Link to="/contact" className="link-no-style nav-link">
              <Nav.Link
                className="text-dark nav-link"
                as="span"
                eventKey="contact"
              >
                Contact
              </Nav.Link>
            </Link>
          </Nav>
        </Navbar.Collapse>

        <Nav className="ml-auto">
          <div className="text-center d-none d-lg-block">
            <span>
              <a
                style={{ paddingRight: "5px" }}
                className="phone-num"
                href={`tel: ${phoneNum}`}
              >
                <MdLocalPhone className="phone-icon" size="25" />
                Call{" "}
              </a>{" "}
              <a className="phone-num" href={`mailto: ${email}`}>
                <MdEmail className="phone-icon" size="25" />
                Email{" "}
              </a>
            </span>
          </div>
        </Nav>

        {/* <Nav className="ml-auto">
            <Form inline onSubmit={e => e.preventDefault()}>
              <Form.Group>
                <FormControl
                  type="text"
                  placeholder="Fake Search"
                  className="mr-2"
                />
              </Form.Group>
              <Link to="/">
              <Button>Fake Button</Button>
              </Link>
            </Form>
          </Nav> */}
        {/* </Container> */}
      </Navbar>
    </>
  )
}

export default CustomNavbar
